import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";

function Coaching() {
  const { t } = useTranslation();
  return (
    <section
      className="bg-black w-full md:h-screen h-auto text-white"
      id="coaching"
    >
      <div className="max-w-[1200px] mx-auto px-6 xl:px-0">
        <h1 className="text-4xl font-extrabold uppercase text-center md:pb-16 pb-8">
          personal coaching
        </h1>
        <div className="grid md:grid-cols-3 md:gap-2 gap-4">
          <div className="flex flex-col gap-4 border-4  border-white sm:mb-10 md:m-0">
            <h2 className="text-3xl font-bold uppercase bg-white text-black p-4 text-center">
              Basic
            </h2>
            <div className="font-bold text-7xl uppercase justify-center flex gap-1 align-middle">
              <div className="flex flex-row">
              <p className="text-4xl text-end pr-1 pb-1 self-end"> &euro;</p>
              <p>79</p>
              </div>
              <div className="flex flex-col justify-between">
                <span className="text-2xl pt-1">.95</span>
                <span className="text-sm lowercase pb-1 ml-1">/{t('month')}</span>
              </div>
            </div>
            <div className="px-16 md:px-12 lg:px-20 pb-4 flex flex-col gap-4">
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                Online personal coaching
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('workoutPlan')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('nutritionAdvice')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                Fitness App
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('communication')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('onlineCheckIn')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('custom')}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-0 border-4 shadow-gray-400 border-white shadow-2xl transition duration-300 md:mx-[-1rem] md:mb-[-3rem] z-50 bg-black md:mt-[-3rem]">
            <h1 className="text-center uppercase font-bold bg-gray-400 p-2 text-sm lg:text-base">{t('marketing')}</h1>
            <h2 className="text-3xl font-bold uppercase bg-white text-black p-4 text-center ">
              Silver
            </h2>
            <div className="font-bold text-7xl uppercase justify-center flex gap-1 align-middle mt-4 mb-4">
              <div className="flex flex-row ">
              <p className="text-4xl text-end pr-1 pb-1 self-end"> &euro;</p>
              <p>249</p>
              </div>
              <div className="flex flex-col justify-between">
                <span className="text-2xl pt-1">.95</span>
                <span className="text-sm lowercase pb-1 ml-1">/{t('month')}</span>
              </div>
            </div>
            <div className="px-16 md:px-12 lg:px-20 pb-4 flex flex-col gap-4">
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('1on1')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>{t('1Session')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('measurements')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('workoutPlan')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('nutritionAdvice')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                Fitness App
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('communication')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('custom')}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4 border-4 border-white">
            <h2 className="text-3xl font-bold uppercase bg-white text-black p-4 text-center">
              Gold
            </h2>
            <div className="font-bold text-7xl uppercase justify-center flex gap-1 align-middle">
              <div className="flex flex-row">
              <p className="text-4xl text-end pr-1 pb-1 self-end"> &euro;</p>
              <p>349</p>
              </div>
              <div className="flex flex-col justify-between">
                <span className="text-2xl pt-1">.95</span>
                <span className="text-sm lowercase pb-1 ml-1">/{t('month')}</span>
              </div></div>
            <div className="px-16 md:px-12 lg:px-20 pb-4 flex flex-col gap-4">
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('1on1')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>{t('2Sessions')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('measurements')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('workoutPlan')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('nutritionAdvice')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                Fitness App
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('communication')}
              </p>
              <p className="flex gap-2">
                <IoCheckmarkSharp size={24} className="flex-shrink-0"/>
                {t('custom')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Coaching;
