import React from "react";
import Socials from "./Socials";

function Footer() {
    return(
        <footer className="bg-black text-white">
            <div className="max-w-[1200px] mx-auto h-24 px-6 xl:px-0 border-t border-gray-600 flex flex-col items-center justify-center gap-2 md:flex-row md:justify-between">
                <p className="md:order-2"><Socials /></p>
                <p className="md:order-1">&copy; {new Date().getFullYear()} Arne De Doncke<a target="_blank" rel="noopener noreferrer" href="https://shorturl.at/hWAtg" className="hover:cursor-text">r</a></p>
                
            </div>
        </footer>
    )
}

export default Footer