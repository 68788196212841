import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

const Testimonial = () => {
  const { t } = useTranslation();
  
  // Fetch translated testimonials
  const testimonialKeys = ['brentM', 'cinziaV'];
  const testimonialList = testimonialKeys.map(key => ({
    name: t(`testimonials.${key}.name`),
    content: t(`testimonials.${key}.content`),
  }));

  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const intervalRef = useRef(null);

  const { name, content } = testimonialList[index];

  useEffect(() => {
    const startInterval = () => {
      intervalRef.current = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % testimonialList.length);
          setFade(true);
        }, 500); // The duration of the fade-out effect
      }, 9000);
    };

    startInterval();

    return () => clearInterval(intervalRef.current);
  }, [testimonialList.length]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % testimonialList.length);
          setFade(true);
        }, 500); // The duration of the fade-out effect
      }, 9000);
    }
  }, [index, testimonialList.length]);

  const handleSelect = (selectedIndex) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setFade(false);
    setTimeout(() => {
      setIndex(selectedIndex);
      setFade(true);
    }, 500); // The duration of the fade-out effect
  };

  return (
    <div className="mx-auto">
      <div>
        <div className={`transition-opacity duration-500 ${fade ? 'opacity-100' : 'opacity-0'}`}>
          <p className="md:text-3xl text-xl pb-4">"{content}"</p>
          <div className="text-center">
            <div>
              <h4 className="md:text-2xl text-lg font-bold">{name}</h4>
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-2 m-0 mt-12">
          {testimonialList.map((item, i) => (
            <button
              className={`w-6 h-1 ${index === i ? "bg-white" : "opacity-40 bg-white"} transition-opacity duration-500`}
              key={i}
              onClick={() => handleSelect(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Testimonial);
