import React from 'react'
import {AiFillInstagram, AiFillLinkedin} from 'react-icons/ai'

function Socials() {
    return (
        <div className='flex justify-center lg:justify-start gap-1'>
            <a href="https://www.instagram.com/arne_dedoncker/" target="_blank" rel="noreferrer noopener"><AiFillInstagram size={40}/></a>            
            <a href="https://www.linkedin.com/in/arne-de-doncker/" target="_blank" rel="noreferrer noopener"><AiFillLinkedin size={40}/></a>
        </div>
    )
}

export default Socials
