import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

function ContactForm() {
  const [result, setResult] = useState("");
  const { t } = useTranslation();

  //destructuring and configuring values from the useForm hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange", // Validate inputs on change
  });

  // Function to handle form submission
  const onSubmit = async (event) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(event)) {
      formData.append(key, value);
    }
    formData.append("access_key", "45fd8d62-d1ba-46b7-a095-73413e6ad043");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Uw bericht werd succesvol verzonden!");
      reset(); // Reset the form fields

    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 pb-8">
      <div>
        <label htmlFor="name" className="">
          {t("formName")}
        </label>
        {errors.name ? (
          <span className="text-red-700 float-right md:text-base text-sm">
            {errors.name.message}
          </span>
        ) : null}
        <input
          {...register("name", { required: "name is required!" })}
          type="text"
          name="name"
          placeholder=""
          className="border-white border-4 bg-black p-2 w-full"
        ></input>
      </div>
      <div>
        <label htmlFor="email" className="">
        {t("formEmail")}
        </label>
        {errors.email ? (
          <span className="text-red-700 float-right md:text-base text-sm">
            {errors.email.message}
          </span>
        ) : null}
        <input
          {...register("email", {
            required: "e-mail address is required!",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
              message: "enter a valid e-mail format!",
            },
          })}
          type="text"
          name="email"
          placeholder=""
          className="border-white border-4 bg-black p-2 w-full"
        ></input>
      </div>
      <div>
        <label htmlFor="telephone" className="">
        {t("formPhone")}
        </label>
        {errors.telephone ? (
          <span className="text-red-700 float-right md:text-base text-sm">
            {errors.telephone.message}
          </span>
        ) : null}
        <input
          {...register("telephone", {
            required: "phone number is required!",
          })}
          type="tel"
          name="telephone"
          placeholder=""
          className="border-white border-4 bg-black p-2 w-full"
        ></input>
      </div>
      <div>
        <label htmlFor="message" className="">
        {t("formMessage")}
        </label>
        {errors.message ? (
          <span className="text-red-700 float-right md:text-base text-sm">
            {errors.message.message}
          </span>
        ) : null}
        <textarea
          {...register("message", {
            required: "message is required!",
            maxLength: {
              value: 500,
              message: "Bericht moet korter zijn dan 500 karakters!",
            },
          })}
          type="text"
          name="message"
          placeholder=""
          className="border-white border-4 bg-black p-2 w-full h-36"
        ></textarea>
      </div>
      <div>
        <button
          type="submit"
          className="md:self-start self-center border-4 py-2 px-8 rounded-3xl hover:bg-white hover:text-black duration-300 ease-in-out font-bold"
        >
          {t("formButton")}
        </button>
        <div className="mt-4">{result}</div>
      </div>
    </form>
  );
}

export default ContactForm;
