import React from "react";
import ContactForm from "../ContactForm";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlinePhone } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  return (
    <section className="bg-black w-full md:h-screen text-white" id="contact">
      <div className="max-w-[1200px] mx-auto px-6 xl:px-0">
        <h1 className="text-4xl font-extrabold uppercase text-center mb-8 lg:mb-16">
          Contact
        </h1>
        <div className="md:grid md:grid-cols-2">
          <div className="lg:pr-24 xl:pr-48 md:pr-20">
          <p className="uppercase text-3xl mb-6">{t('contactheader')}</p>
          <p className="mb-6">{t('contactDescription')}</p>
          <p className="flex text-center gap-4 mb-2"><AiOutlineMail size={24} className="flex-shrink-0"/>coach@arnededoncker.com</p>
          <p className="flex text-center gap-4 mb-8"><AiOutlinePhone size={24} className="flex-shrink-0"/> + 32 486 72 53 05</p>
          </div>
          <ContactForm/>
        </div>
        <div>

        </div>
      </div>
    </section>
  );
}

export default Contact;
