import React from 'react'
import Contact from './Contact'
import Header from './Header'
import Coaching from './Coaching'
import Ervaringen from './Ervaringen'

function Home() {
    return (
        <>
        <Header />
        <Coaching />
        <Ervaringen />
        <Contact />
        </>
    )
}

export default Home
