import React from "react";
import Socials from "../Socials";
import { Link } from "react-scroll";
import picture from "../../images/Foto BF color gradient.png";
import glasses from "../../images/Thug Life Glasses.png";
import { useState } from "react";

function Header() {
  const [clickCount, setClickCount] = useState(0);
  const [showGlasses, setShowGlasses] = useState(false);

  const handleClick = () => {
    setClickCount(clickCount + 1);

    if(clickCount === 4){
      setShowGlasses(true);
      setClickCount(0);
    }}
  return (
    <header className="w-full h-screen bg-black text-white">
      <div className="max-w-[1200px] mx-auto pt-[5vh] px-6 xl:px-0">
        <div className="grid lg:grid-cols-2 items-center  lg:gap-0">
          <div className="flex flex-col gap-4">
            <h1 className="text-5xl sm:text-6xl text-center lg:text-left font-bold">
              Coach Arne
            </h1>
            <p className="text-center lg:text-justify lg:max-w-[600px] text-gray-300 ">
              Certified Personal Trainer
            </p>
            <Link className="lg:self-start self-center"
              to="contact"
              spy={true}
              smooth={true}
              offset={-25}
              duration={750}
            >
              <button className="lg:self-start self-center border-4 py-2 px-8 rounded-3xl hover:bg-white hover:text-black duration-300 ease-in-out font-bold">
                Contact
              </button>
            </Link>
            <Socials />
          </div>
          <div className="relative lg:w-[500px] max-w-full justify-self-center transition-opacity duration-700 opacity-100">
            {/* <img src={picture} alt="Arne De Doncker" /> */}
            <img src={picture} alt="Arne De Doncker" onClick={handleClick}/>
            {showGlasses ? <img src={glasses} alt="thug life glasses" className="absolute lg:top-[95px] lg:left-[208px] top-[18.5%] left-[40.3%] z-5 lg:w-24 animate-flyInFromTop w-[22%]"/> : null}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header; 
