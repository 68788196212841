import React from "react";
import Testimonial from "../Testimonial";
import { useTranslation } from "react-i18next";

function Ervaringen() {
  const { t } = useTranslation();

  return (
    <section className="w-full h-screen bg-black text-white" id="Ervaringen">
      <div className="max-w-[1200px] mx-auto px-6 xl:px-0">
        <h1 className="text-4xl font-extrabold uppercase text-center py-8">
          {t('experiences')}
        </h1>
        <div className="flex items-center justify-center h-[80vh]">
          <div className="text-center max-w-[1000px]">
            <Testimonial />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ervaringen;
