import React from "react";
import { useState } from "react";
import {} from "react-icons/fa";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import logo from "../images/De Doncker Logo Black no line cropped.png";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import flagBe from "../images/Belgium.svg";
import flagUsa from "../images/USA.svg";
import { t } from "i18next";

function Navbar() {
  const { i18n } = useTranslation();
  const [nav, setNav] = useState(false);
  const languages = [
    { code: "nl", flag: flagBe },
    { code: "en", flag: flagUsa },
  ];

  const handleNav = () => {
    setNav((prev) => !prev);
  };

  return (
    <div className="text-white bg-black">
      <nav className="flex items-center justify-between max-w-[1200px] h-20 mx-auto px-6 xl:px-0 border-b border-gray-600">
        <img src={logo} alt="logo" className="h-[30px]" />
        <h1 className="hidden">Home</h1>
        <ul className="hidden lg:flex flex-row gap-8">
          {/* <li className="uppercase hover:cursor-pointer hover:opacity-80 duration-200 ">
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-50}
              duration={750}
            >
              Over
            </Link>
          </li> */}
          
          <li className="uppercase hover:cursor-pointer hover:opacity-80 duration-200">
            <Link
              to="coaching"
              spy={true}
              smooth={true}
              offset={-25}
              duration={705}
            >
              Coaching
            </Link>
          </li>
          <li className="uppercase hover:cursor-pointer hover:opacity-80 duration-200">
            <Link
              to="Ervaringen"
              spy={true}
              smooth={true}
              offset={0}
              duration={750}
            >
              {t("experiences")}
            </Link>
          </li>
          <li className="uppercase hover:cursor-pointer hover:opacity-80 duration-200">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-25}
              duration={750}
            >
              Contact
            </Link>
          </li>
          {languages.map((language) => (
            <button
              onClick={() => i18n.changeLanguage(language.code)}
              title="Change Language"
              key={language.code}
              className={
                language.code === i18n.language
                  ? "hidden"
                  : "hover:cursor-pointer hover:opacity-80 duration-200"
              }
            >
              <img src={language.flag} alt={`Flag of ${language.code}`} className="w-7 border border-white border-opacity-20"/>
            </button>
          ))}
        </ul>
        <div className="flex gap-8 lg:hidden cursor-pointer">
        {languages.map((language) => (
            <button
              onClick={() => i18n.changeLanguage(language.code)}
              title="Change Language"
              key={language.code}
              className={
                language.code === i18n.language
                  ? "hidden"
                  : "hover:cursor-pointer hover:opacity-80 duration-200"
              }
            >
              <img src={language.flag} alt={`Flag of ${language.code}`} className="w-7 border border-white border-opacity-20"/>
            </button>
          ))}
          <div onClick={handleNav} className="lg:hidden cursor-pointer">
            <AiOutlineMenu size={25} />
          </div>
        </div>
        <div
          className={`fixed right-0 top-0 w-full h-full bg-black z-10 ease-in-out duration-700 ${
            !nav ? "translate-x-full" : null
          }`}
        >
          <AiOutlineClose
            size={25}
            onClick={handleNav}
            className="cursor-pointer m-6 fixed right-0"
          />

          <ul className="flex flex-col h-screen gap-10 cursor-pointer items-center justify-center text-2xl">
            {/* <li className="uppercase hover:cursor-pointer">
              <Link
                onClick={handleNav}
                to="about"
                spy={true}
                smooth={true}
                offset={-50}
                duration={750}
              >
                Over
              </Link>
            </li> */}
            <li className="uppercase hover:cursor-pointer">
              <Link
                onClick={handleNav}
                to="coaching"
                spy={true}
                smooth={true}
                offset={-25}
                duration={705}
              >
                Coaching
              </Link>
            </li>
            <li className="uppercase hover:cursor-pointer">
              <Link
                onClick={handleNav}
                to="Ervaringen"
                spy={true}
                smooth={true}
                offset={0}
                duration={750}
              >
                {t("experiences")}
              </Link>
            </li>
            <li onClick={handleNav} className="uppercase hover:cursor-pointer">
              <Link
                onClick={handleNav}
                to="contact"
                spy={true}
                smooth={true}
                offset={-25}
                duration={750}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
